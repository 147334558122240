<template>
    <v-container fluid class="login_container">
        <v-row row class="text-xs-center" align="center"  >
            <v-col class="login-box">
                <v-card flat>
                    <v-card-title primary-title>
                    <h4 class="white--text">Ingresar</h4>
                    </v-card-title>
                    <v-form>
                    <v-text-field
                        prepend-icon="person"
                        v-model="userName"
                        label="Usuario"
                        ref="userName"
                        dark
                    ></v-text-field>
                    <v-text-field
                        prepend-icon="lock"
                        v-model="password"
                        label="Contraseña"
                        type="password"
                        @keyup.enter="login"
                        dark
                    ></v-text-field>
                    <v-card-actions>
                        <v-btn primary @click="login">Login</v-btn>
                    </v-card-actions>
                    </v-form>
                </v-card>
                <!-- </v-container> -->
            </v-col>
        </v-row>
    </v-container>  
</template>

<script>
import {ApiAuth} from "./ApiAuth"
import messagesMixin from "../../mixins/messagesMixin"

export default {
    name:"Login",
    mixins:[messagesMixin],

    data(){
        return{
            userName:"",
            password:"",
            api: new ApiAuth()
        }
    },
    mounted(){
        this.$refs.userName.focus()
        localStorage.removeItem("usuario")
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        localStorage.removeItem("Sucursal")
        localStorage.removeItem("SucursalId")
    },
    methods:{
        async login(){
            let userName = this.userName
            let pass = this.password

            if(userName==""){
                this.$swal("Requerido","Usuario Requerido","warning")
                return
            }
            if(pass=="" || pass.length<=3){
                this.$swal("Requerido","Password Requerido o debe ser mayor a tres dígitos","warning")
                return
            }

            let token = await this.api.login(userName,pass)
            if('access' in token ){
                localStorage.setItem("usuario",userName)
                localStorage.setItem("access",token.access)
                localStorage.setItem("refresh",token.refresh)
                this.$root.$emit("login",userName)
                setTimeout(() => this.$router.push("/inicio"), 1000);
                const details = this.api.getUserDetail()
                details.then((value) => {
                    localStorage.setItem("groups", value["groups"])
                })                
                // const promise1 = localStorage.getItem('details')
                // promise1.then((value) => {
                //     console.log(value)
                // })

            }else if('detail' in token){
                this.message("Usuario y/o password incorrectos", "Error", "error")
            }else if('error' in token){
                this.message(token.error, "Error", "error")
            }else{
                this.message("Error Inesperado")
            }

            // localStorage.setItem("username",username)
            // this.$router.push("/")
            // this.$root.$emit("login",username)
        }
    }
    
}
</script>

<style scoped>
footer{
  background-color: #065972;
    padding: 1rem 0;
    width: 100%;
    bottom: 0;
}
</style>
